@font-face {
    font-family: VisbyCF;
    font-weight: bold;
    src: url("../fonts/VisbyCF-Bold.otf") format("opentype");
}

@font-face {
    font-family: VisbyCF;
    src: url("../fonts/VisbyCF-Regular.otf") format("opentype");
}

@font-face {
    font-family: VisbyCF;
    font-weight: lighter;
    src: url("../fonts/VisbyCF-Light.otf") format("opentype");
}

html, html body, body {
    font-family: 'VisbyCF', serif;
    font-size: 18px;
    color:#333;
}

#progressbar {margin-top:20px;}
#logo a {
    display:block;
    width:250px;
    height: 75px;
    background: url('../images/logo.png') no-repeat;
    margin:10px auto;
}

#sectionProgress {font-weight:bold; height:22px;}

.question {margin: 20px 0px 10px 0px;}

.question p {
    font-weight: bold;
    font-size: 30px;
    color:#116b2e;
    line-height: 1.25em;
    margin-bottom: 15px;
}
.btn-success1 {
    background-color: #ADD035;
}
.btn-success1:hover {
    background-color: #ADD035;
}
.btn-350 {
    max-width:350px; 
    text-align: center;
    margin:5px auto;
}

.btn-scroll {
    max-width:350px; 
    text-align: center;
    margin:5px auto;
    display: block;
}

.question small {font-size:.85em; line-height: 15px;}

.buttons {
    display: flex;
    justify-content: center;
    list-style: none;
    margin:0px;
    padding:0px;
    justify-content: center;
}

.buttons button {
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 25%;
    flex: 0 0 25%;
    min-height: 190px;
    transition: transform .3s ease-in-out;
    outline: none;
    box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 15%);
    border: 1px solid #8ac228;
}

@media (hover: hover) {
    .buttons button:hover {
        box-shadow: 2px 0px 25px 0px rgba(173, 208, 54, 0.5) inset;
        -webkit-box-shadow: 2px 0px 25px 0px rgba(173, 208, 54, 0.5) inset;
        -moz-box-shadow: 2px 0px 25px 0px rgba(173, 208, 54, 0.5) inset;
        transition: transform .3s ease-in-out;
    }
}

.buttons button.active {
    border: 1px solid #f08c20;
    box-shadow: 2px 0px 25px 0px rgba(240,140,32,0.5) inset;
    -webkit-box-shadow: 2px 0px 25px 0px rgba(240,140,32,0.5) inset;
    -moz-box-shadow: 2px 0px 25px 0px rgba(240,140,32,0.5) inset;
    transition: transform .3s ease-in-out;
}

.buttons button span {
    display: block;
    text-align: left;
}

.buttons button span.icon {
    margin: 0 auto 0;
    height:70px; 
    width:70px;
    background-size: contain !important;
    flex-basis: 70px;
}

.buttons button span.text {
    height: initial;
    margin: 5px;
    line-height: 22px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: .3px;
    color: #142438;
    flex-basis: calc(100% - 70px);
}

.refinance span.icon {
    background:url('../images/refinance.png') no-repeat;
}
.buy-a-home span.icon {
    background:url('../images/buy-a-home.png') no-repeat;
}

.primary-residence span.icon {
    background:url('../images/primary-residence.png') no-repeat;
}
.secondary-home span.icon {
    background:url('../images/secondary-home.png') no-repeat;
}
.inventment-property span.icon {
    background:url('../images/inventment-property.png') no-repeat;
}

.single-family span.icon {
    background:url('../images/single-family.png') no-repeat;
}
.multi-family span.icon {
    background:url('../images/multi-family.png') no-repeat;
}
.town-home span.icon {
    background:url('../images/town-home.png') no-repeat;
}
.mobile-home span.icon {
    background:url('../images/mobile-home.png') no-repeat;
}
.duplex-home span.icon {
    background:url('../images/duplex-home.png') no-repeat;
}

.excellent span.icon {
    background:url('../images/excellent.png') no-repeat;
}
.good span.icon {
    background:url('../images/good.png') no-repeat;
}
.average span.icon {
    background:url('../images/average.png') no-repeat;
}
.poor span.icon {
    background:url('../images/poor.png') no-repeat;
}

.bf-no span.icon {
    background:url('../images/bf-no.png') no-repeat;
}
.bankruptcy span.icon {
    background:url('../images/bankruptcy.png') no-repeat;
}
.foreclosure span.icon {
    background:url('../images/foreclosure.png') no-repeat;
}
.bf-both span.icon {
    background:url('../images/bf-both.png') no-repeat;
}

.not-too-bad span.icon {
    background:url('../images/not-too-bad.png') no-repeat;
}
.some-issues span.icon {
    background:url('../images/some-issues.png') no-repeat;
}
.major-issues span.icon {
    background:url('../images/major-issues.png') no-repeat;
}

.yes span.icon {
    background:url('../images/yes.png') no-repeat;
}
.no span.icon {
    background:url('../images/no.png') no-repeat;
}
.maybe span.icon {
    background:url('../images/maybe.png') no-repeat;
}

.employed span.icon {
    background:url('../images/employed.png') no-repeat;
}
.self-employed span.icon {
    background:url('../images/self-employed.png') no-repeat;
}
.retired span.icon {
    background:url('../images/retired.png') no-repeat;
}
.other span.icon {
    background:url('../images/other.png') no-repeat;
}

.price-value {
    font-size:1.5em;
}

.range-min {
    float: left; 
    font-size:18px;
}
.range-max {
    float: right; 
    font-size:18px;
}

.slider.slider-horizontal {
    width:100% !important;
}

.slider {
    -webkit-appearance: none !important;
    width: 100% !important;
    height: 15px !important;
    border-radius: 5px !important;
    outline: none !important;
    opacity: 1 !important;
    -webkit-transition: .2s !important;
    transition: opacity .2s !important;
}

.slider-handle {
    background-color: #157f37 !important;
    background-image: -webkit-linear-gradient(top, #38b560 0%, #157f37 100%) !important;
    background-image: -o-linear-gradient(top, #38b560 0%, #157f37 100%) !important;
    background-image: linear-gradient(to bottom, #38b560 0%, #157f37 100%) !important;
    background-repeat: repeat-x !important;
    filter: none !important;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    appearance: none !important;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    background-color: #18843b !important;
    background-image: linear-gradient(to bottom,#18843b 0,#aed7bb 100%) !important;
    cursor: pointer !important;
}
  
.slider::-moz-range-thumb {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    background-color: #18843b !important;
    background-image: linear-gradient(to bottom,#18843b 0,#aed7bb 100%) !important;
    cursor: pointer !important;
}

.slider-handle {
    width:30px !important;
    height: 30px !important;
    top:-5px !important;
    border:#fff 3px solid !important;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
}
.slider-selection {
    background:#116b2e !important;
}
.slider-track-high {
    background:#aed7bb !important;
}

.section-hide {display:none}
.section-show {display:block}

.parsley-errors-list {list-style-type: none; margin:0px; padding:0px;}
.parsley-errors-list li {color:#C00; font-weight: bold; font-size:14px}

/* modal styling for pivacy policy and terms of use */
.contentModalText .content {
    text-align: left;
    background-color: #FFFF;
    padding: 16px;
}
.contentModalText h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
}
.contentModalText h2 {
    font-size: 1.53125rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText h3 {
    font-size: 1.3125rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText h4 {
    font-size: 1.09375rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #000;
}
.contentModalText p {
    font-size: 14px;
    line-height: 1.5em;
    color: #495057;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 500;
}
.contentModalText .header {
    padding: 20px 20px 20px;
    background: #198754;
    color: #FFFFFF;
    width: 100%;
}
.contentModalText .header h3 {
    text-transform: uppercase;
    font-size: 22px;
}

.contentModalText table,
th,
td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    font-size: 14px !important;
    text-align: left;
    padding: 5px;
    color: #495057;
    line-height: 1.4em;
    margin-bottom: 10px;
}

.contentModalText th {
    color: #495057;
    font-weight: 700;
}
.contentModalText ul li {
    list-style-type: disc !important;
}
.contentModalText ol li {
    list-style-type: decimal !important;
}
.contentModalText ul {
    /* margin-left: 40px !important; */
    font-size: 14px !important;
    line-height: 1.5em !important;
    color: #495057 !important;
    margin-bottom: 10px !important;
}
.contentModalText ol {
    /* margin-left: 40px !important; */
    font-size: 14px !important;
    line-height: 1.5em !important;
    color: #495057 !important;
    margin-bottom: 10px !important;
} 

@media (max-width: 1050px) {
    .buttons button span.text {
        font-size: 15px;
    }
}

@media (max-width: 850px) {
    .buttons button span.text {
        font-size: 14px;
    }
}

@media (max-width: 650px)
{
    .question p {
        font-weight: bold;
        font-size: 22px;
    }

    .buttons {
        overflow-wrap: break-word;
        flex-wrap: wrap;
    }

    .buttons button span.icon {
        float: left;
        background-repeat: no-repeat;
        background-position: 0 0;
        margin-top: 1px;
        height: 40px;
        width: 40px;
        margin-left: 0;
        margin-right: 20px;
        background-size: contain !important;
        flex-basis: 40px;
    }

    .buttons button {
        background-image: url('../images/arrow.png');
        background-repeat: no-repeat;
        background-position: right 20px top 50%;
        background-size: 20px 20px;
        max-width: 450px;
        margin: 0 auto 15px;
        display: flex;
        align-items: center;
        text-align: left;
        background-color: #fff;
        padding: 15px 44px 15px 20px;
        flex: 0 0 100%;
        min-height: auto;
    }

    @media (hover: hover) {
        .buttons button:hover {
            background-image: url('../images/arrow-highlight.png');
        }
    }

    .buttons button.active {
        background-image: url('../images/arrow-selected.png');
    }

    .buttons button span.text {
        line-height: 22px;
        float: left;
        font-size: 17px;
        padding-left: 5px;
        font-weight: 400;
        margin-top: 8px;
        letter-spacing: .02em;
        height:auto;
        flex-basis: calc(100% -  40px);
    }
    .section-question {margin: 0px 40px;}
}

.contentModal {
    position: fixed;
    z-index: 1000;
    width: 800px;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow-y: auto;
    border-radius: 4px;
    outline: none;
    margin: 20px;
    max-height: 100vh;
    padding: 0;
    border: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

@media only screen and (max-width: 1000px) {
    .contentModal {
        width: 80%;
        margin: 0;
    }
}

.contentModalHeader {
    background-color: #198754;
    color: white;
    padding: 20px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}

.contentModalText {
    padding: 20px;
    color: #212529;
    font-family: 'Montserrat', sans-serif;
}

.contentModalOverlay {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.pointer-cursor {
    cursor:pointer;
}

.form-back-button {
    cursor: pointer;
}

.contentModalText ul {
    list-style-type: circle;
}

.contentModalText li {
    margin: 5px;
}

.adDisclosure li:before {
    content:"\2714\0020";
    color: rgb(8, 193, 119);

}
.adDisclosure ul {
    list-style-type: none;
}

.rateTable {
    padding: 8px;
    border: 1px solid currentColor; 
    background-color: rgb(239, 240, 224);
    width: 100%;
}

.rateTableAPR {
    width: 11%;
}

.fullTable {

}

@media screen and (max-device-width: 321px) {
    .buttons button span.text {
        font-size: 12px;    
        word-break: break-all;
    }

    .buttons button {
        background-position: right 12px top 25px;
        padding-right: 36px;
    }
}

#ppTag {
    cursor: pointer
}